let loginmr={
    eree:false
}
const chineseValues={
    cp:'产品', // 产品
    payType:'支付单位',
    cpParameters:'单位', // 产品的单位 元（积分等）/条（次，套，等）
    zsprice:'日常价',   // 展示日常价
	novip:'零售价',     // 展示当此的零售价
	v1:'VIP1价',        // vip1客户价
	v2:'VIP2价',        // vip2客户价
	v3:'VIP3价',        // vip3客户价
    vipType:'VIP等级',    //客户端用，VIP等级 无等级就是看novip的价格
	offline:false,   // ***
    zz:'载重',    //载重
	zw:'轴位',    //轴位
	jl:'距离',    //距离
	lk:'路况',    //路况
	sd:'速度',     //速度
	ImageUrls:'图片',   //图片路径
	cptext:'产品介绍',    //产品介绍
	cjtext:'厂家介绍',    //厂家介绍
}
const check={
    cp:true,
    price: {
        // 价格
        zsprice: true, // 展示日常价
        novip: true, // 展示当此的零售价
        v1: true, // vip1客户价
        v2: true, // vip2客户价
        v3: true, // vip3客户价
    },
    cpParameters:true,
    zcx_imagevalue:[],
    cptd:[]
}
export default {
    /**
     * 验证输入值是否为数字
     * @param {Object} event - 事件对象
     * @returns {DOMRect} 元素的边界矩形
     */
    div_move(event){
        const element = event.currentTarget;
        try {
            return element.getBoundingClientRect()
        } catch (error) {
            return null
        }
    },
    zcx_msgbox(thiss,tf,robj,timeobj){
        const e =thiss
        if(tf){
            const loading = e.$loading({
                lock: true,
                text: `${robj.str}`,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            let timeoutId = setTimeout(() => {
                loading.close();
              }, robj.tiem);
            return {loading,timeoutId}
        }else if(!tf){
            clearTimeout(timeobj.timeoutId); // 清除定时器
            timeobj.loading.close(); // 关闭 loading
        }
    },
    zcx_cacheItem(type,name,obj){
        if(typeof obj === 'object' && type === 1){
            localStorage.setItem(name,JSON.stringify(obj));
        }else if(type === 2){
            try {
                return JSON.parse(localStorage.getItem(name))
            } catch (error) {
                return localStorage.getItem(name)
            } 
        }else if(type === 3){
            localStorage.removeItem(name);
        }
    },
    zcx_usermr(type,data){
        if(type === 1){
            return loginmr
        }else if(type === 2 && data !== null){
            loginmr={...data}
        } 
    },
    zcx_ChineseValue(key){
        try {
            return chineseValues[key]
        } catch (error) {
            return null
        }
    },
    zcx_Match(arr,value){
        // console.log(arr,value)
        const regexk = /\+/;
        let regexCondition='';
        if (regexk.test(value)) {
          const value2 = value.split('').join('.*');
           regexCondition = value2.replace(/[+]/g, '\\$&');
        } else {
            regexCondition = value.split('').join('.*');
        }
          const regex = new RegExp('.*' + regexCondition + '.*', 'i');
          // console.log(regex)
          const fuzzyMatch = (item, regex) => {
            return regex.test(item);
          };
          return arr.filter(item => fuzzyMatch(item, regex));
    },
    zcx_MatchObject(obj, types, value) {
        // console.log(obj, types, value,11123);
        const processedValue = value.replace(/\s+/g, ''); // 去掉所有空格
const regex = new RegExp(processedValue.split('').join('.*'), 'i'); // 添加通配符// 创建不区分大小写的正则表达式
        let value2=''
        return Object.keys(obj).map(key => obj[key]) // 获取每个子对象
        .filter(subObj => {
            return types.some((type, index) => {
                if(index === 0){
                    value2=''
                }
                value2 += subObj[type]
                if(index === types.length - 1){
                    return subObj && regex.test(value2);// 检查是否有任一字段匹配
                } 
            });
            
        });
    },
    zcx_check(obj,checkObj = check) {
        for (const key in checkObj) {
            
            if (typeof checkObj[key] === 'object' && !Array.isArray(checkObj[key])) {
                // 如果是嵌套对象，递归检查
                if (!this.zcx_check(obj[key], checkObj[key])) {
                    return false;
                }
            } else {
                // 检查是否存在且已填值
                if((key in obj) && Array.isArray(checkObj[key]) && obj[key].length === 0){ return false;}
                if (!(key in obj) || obj[key] === '' || obj[key] === null || obj[key] === undefined) {
                    return false;
                }
            }
        }
        return true;
    },
    
    zcx_ranstring(length, date) {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            result += chars[randomIndex];
        }
        if (date) {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const hours = String(currentDate.getHours()).padStart(2, '0');
            const minutes = String(currentDate.getMinutes()).padStart(2, '0');
            const seconds = String(currentDate.getSeconds()).padStart(2, '0');
            const dateString = `${year}${month}${day}${hours}${minutes}${seconds}`;
            result += dateString;
        }
        return result;
    },
    /**
 * 递归或迭代访问和修改嵌套对象的属性
 * @param {Object} obj - 目标对象
 * @param {Array} keys - 属性路径的数组
 * @param {*} newValue - 如果提供了此值，则设置最终属性为此值
 * @returns {*} - 返回最终属性的值或被修改的对象
 */
 zcx_getNestedValue(obj, keystring, newValue = undefined) {
    const keys = keystring.split('.');
    let current = obj;
    // 遍历 keys，逐级访问
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      // 如果到达最后一个 key 且有 newValue，则修改值
      if (i === keys.length - 1 && newValue !== undefined) {
        current[key] = newValue;
      }
      // 向下深入到下一个层级
      current = current[key];
    }
    return current;
  },
}