<template>
  <div class="viewto">
    <!-- <el-radio-group v-model="direction">
      <el-radio label="ltr">从左往右开</el-radio>
      <el-radio label="rtl">从右往左开</el-radio>
      <el-radio label="ttb">从上往下开</el-radio>
      <el-radio label="btt">从下往上开</el-radio>
    </el-radio-group> -->
    <!-- <ZcxDropdown zcxdata.ZcxDropdowndata></ZcxDropdown> -->
    <ZcxDropdown ref="dropdown" style="z-index: 5" @close-event="handleClose" />
    <!-- <p v-for="index in ins" :key="index">商城</p> -->
    <!-- <div class="viewtop">
      <button style="margin-left: 0">上架商品</button>
      <button>下架商品</button>
    </div> -->

    <div class="viewform left-top">
      <div class="formlist2">
        <div class="formlist21">
          <p>产品:</p>
          <input
            v-model="form.cp"
            :data-field="'form.cp'"
            name="form.cp"
            :data-key="'cp'"
            readonly
            type="text"
            placeholder="(必填)请选择产品"
            @click="ZcxDropdownadd($event)"
          />
          <p
            class="el-icon-s-operation"
            style="margin-left: 0.3vw; font-size: 0.8vw"
          ></p>
        </div>
        <div class="formlist21" style="justify-content: flex-start">
          <p>单位:</p>
          <input
            v-model="form.cpParameters"
            type="text"
            placeholder="(必填)请输入单位(例如:条,套,个...)"
          />
        </div>
        <div
          class="formlist21"
          style="flex-direction: column; align-items: stretch"
        >
          <p>价格设置:</p>
          <div class="formobjlist formlist2" style="margin-left: 0">
            <div
              class="formlist21"
              v-for="(item, index) in form.price"
              :key="index"
            >
              <p style="font-size: 0.6vw">{{ chineseValues(index) }}:</p>
              <input
                v-model="form.price[index]"
                type="number"
                placeholder="(必填)请输入价格"
              />
            </div>
            <div style="margin-bottom: 0.5vw"></div>
          </div>
        </div>

        <div
          class="formlist21"
          style="flex-direction: column; align-items: stretch"
        >
          <div style="display: flex; flex-direction: row">
            <p>产品参数:</p>
            <button
              @click="addcptd"
              style="
                width: 5vw;
                color: #40a9ff;
                font-size: 0.5vw;
                border: 0.01vw solid #40a9ff;
                margin-left: 0.5vw;
                border-radius: 0.2vw;
                height: 1vw;
              "
            >
              添加+
            </button>
          </div>
          <div class="formobjlist formlist2" style="margin-left: 0">
            <div
              class="formlist21"
              v-for="(item, index) in form.cptd"
              :key="index"
            >
              <input
                v-model="form.cptd[index][0]"
                type="text"
                placeholder="参数名"
                style="width: 4vw; height: 1vw"
              />
              <p style="margin-left: 0.3vw">:</p>
              <input
                v-model="form.cptd[index][1]"
                type="text"
                placeholder="需填写相关特性值"
                style="width: 5vw; height: 1vw; margin-right: 0.5vw"
              />
              <p
                class="el-icon-delete"
                style="font-size: 0.8vw"
                @click="delcptd(index)"
              ></p>
            </div>
            <div style="margin-bottom: 0.5vw"></div>
          </div>
        </div>
      </div>

      <div class="formlist2" style="justify-content: space-evenly">
        <div class="formtextlist input-container">
          <p>产品介绍:</p>
          <textarea
            placeholder="请输入产品介绍"
            v-model="form.cptext"
          ></textarea>
        </div>
        <div class="formtextlist">
          <p>厂家介绍:</p>
          <textarea
            placeholder="请输入厂家介绍"
            v-model="form.cjtext"
          ></textarea>
        </div>
      </div>

      <div
        class="formlist2"
        style="
          border: 1px solid #40a9ff;
          width: 11.3vw;
          flex-direction: row;
          align-content: flex-start;
          flex-wrap: wrap;
          margin-top: 0.5vw;
          border-radius: 0.3vw;
        "
      >
        <p
          style="
            display: flex;
            width: 11vw;
            margin: 0.3vw 0;
            margin-left: 0.3vw;
          "
        >
          轮播图:
        </p>
        <input
          id="file-upload"
          type="file"
          @change="onFileChange($event, 'lunbo')"
          multiple
        />
        <div
          v-for="(item, index) in form.zcx_imagevalue"
          :key="index"
          style="
            display: flex;
            width: 5vw;
            height: 5vw;
            margin: 0.3vw;
            position: relative;
          "
        >
          <p
            class="el-icon-delete"
            style="
              position: absolute;
              top: 0;
              right: 0;
              cursor: pointer;
              background-color: #ffffff;
            "
            @click="delimage(index)"
          ></p>
          <img
            v-if="item.base64"
            :src="item.base64"
            alt="File Preview"
            style="width: 5vw; height: 5vw"
          />
        </div>
        <label
          for="file-upload"
          class="custom-file-upload el-icon-plus"
        ></label>
      </div>

      <div
        class="formlist2"
        style="justify-content: flex-end; margin-left: auto"
      >
        <button
          style="
            width: 8vw;
            height: 2vw;
            color: #ffffff;
            font-size: 0.7vw;
            background-color: chocolate;
            height: auto;
            border-radius: 0.3vw;
          "
          @click="sjsptj"
        >
          提交
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mall",

  created() {},
  computed: {
    mr() {
      return this.$zcx.zcx_usermr(1).ret;
    },
    check() {
      this.$zcx.zcx_check(this.form);

      if (
        this.form.cp === "" ||
        this.form.cp === null ||
        this.form.cp === undefined
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      ins: 1,
      ZcxDropdowndata: {
        visible: false,
        data: {},
        top: null,
        left: null,
        width: null,
        visible2: false,
      },
      form: {
        cp: "", // 产品
        price: {
          // 价格
          zsprice: null, // 展示日常价
          novip: null, // 展示当此的零售价
          v1: null, // vip1客户价
          v2: null, // vip2客户价
          v3: null, // vip3客户价
        },
        payType: null, //支付单位 元，积分等...
        cpParameters: null, // 产品的单位 条（次，套，等）
        cptd: [],
        vipType: null, //客户端用，VIP等级 无等级就是看novip的价格
        offline: false, // ***
        ImageUrls: {}, //图片路径
        cptext: null, //产品介绍
        cjtext: null, //厂家介绍
        selectedFile: null,
        base64Data: "",
        previewUrl: null,
        zcx_imagevalue: [],
      },
    };
  },
  methods: {
    delimage(e) {
      this.form.zcx_imagevalue.splice(e, 1);
    },
    async onFileChange(event, move) {
      const file = event.target.files[0];
      if (!file) {
        alert("请选择一个文件");
        return;
      }
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          // console.log(e.target.result);
          const fileType = file.type.split("/")[1];
          this.form.zcx_imagevalue.push({
            imagemove: move,
            name: `${fileType}`,
            base64: e.target.result,
            url: null,
          });
        };
        reader.readAsDataURL(file);
      }

      // console.log(this.form.zcx_imagevalue, 4444);

      // if (file && file.type.startsWith("image/")) {
      //   const reader = new FileReader();
      //   reader.onload = (e) => {
      //     console.log(e.target.result);
      //     this.form.previewUrl = e.target.result;
      //   };
      //   reader.readAsDataURL(file);
      // } else {
      //   this.form.previewUrl = null;
      //   alert("请选择一个图像文件进行预览。");
      // }

      // this.selectedFile = file;
      // // console.log(this.selectedFile);
      // const reader = new FileReader();
      // reader.readAsDataURL(file);
      // // console.log(reader);
      // reader.onload = () => {
      //   this.base64Data = reader.result.split(",")[1]; // 去掉data:image/jpeg;base64,前缀
      // };
      // reader.onerror = () => {
      //   alert("文件读取失败");
      // };
    },
    async uploadFile(base64Data, name, adminid) {
      // console.log(base64Data, name, adminid);
      // if (!this.base64Data || !this.selectedFile) {
      //   alert("请选择一个文件");
      //   return;
      // }

      try {
        const response = await this.$post(
          "https://env-00jxgwvpsgbo.dev-hz.cloudbasefunction.cn/upiamge",
          {
            // fileName: this.selectedFile.name,
            // fileData: this.base64Data, // 发送 Base64 编码的文件内容
            // adminid: "dl1568113213",
            fileName: name,
            fileData: base64Data, // 发送 Base64 编码的文件内容
            adminid: adminid,
          }
        );
        // console.log(response);

        if (response.code === 200) {
          return response;
          // alert("文件上传成功: " + response.fileID);
        } else {
          return response;
          // alert("文件上传失败");
        }
      } catch (error) {
        console.error("文件上传出错:", error);
        alert("文件上传出错");
      }
    },

    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async sjsptj() {
      let tiemMsg;
      tiemMsg = this.$zcx.zcx_msgbox(
        this,
        true,
        { str: "正在上传，请不要离开页面..", tiem: 20000 },
        {}
      );
      if (this.$zcx.zcx_check(this.form)) {
        for (const key in this.form.zcx_imagevalue) {
          this.$zcx.zcx_msgbox(this, false, {}, tiemMsg);
          tiemMsg = this.$zcx.zcx_msgbox(
            this,
            true,
            { str: "正在上传，请不要离开页面(开始上传图片)", tiem: 20000 },
            {}
          );
          const base64Data = this.form.zcx_imagevalue[key].base64.split(",")[1];
          const name = `${this.$zcx.zcx_ranstring(20, true)}.${
            this.form.zcx_imagevalue[key].name
          }`;
          const adminid = this.$zcx.zcx_usermr(1).ret.adminid;
          const ret = await this.uploadFile(base64Data, name, adminid);
          if (ret.code === 200) {
            this.form.zcx_imagevalue[key].base64 = null;
            this.form.zcx_imagevalue[key].name = null;
            this.form.zcx_imagevalue[key].url = ret.url;
          }
          // console.log(this.form.zcx_imagevalue[key].base64.split(",")[1]);
        }
        this.$zcx.zcx_msgbox(this, false, {}, tiemMsg);
        this.$zcx.zcx_msgbox(
          this,
          true,
          { str: "上传图片完成", tiem: 1000 },
          {}
        );
        this.$zcx.zcx_msgbox(this, false, {}, tiemMsg);
        this.$zcx.zcx_msgbox(
          this,
          true,
          { str: "正在上传商品资料", tiem: 1000 },
          {}
        );
        const ret = await this.$post(
          "https://env-00jxgwvpsgbo.dev-hz.cloudbasefunction.cn/zcxcodeapi",
          {
            data: {
              type: "zcx_insert",
              rp: {
                zcx_db: "opendb-mall-goods",
                malldata: { ...this.form },
                adminid: `${this.mr.adminid}`,
              },
            },
          },
          {},
          {
            zcxy: "web",
            "Content-Type": "application/json",
          }
        );
        if (ret.code === 0) {
          this.$zcx.zcx_msgbox(this, false, {}, tiemMsg);
          this.$zcx.zcx_msgbox(
            this,
            true,
            { str: "商品添加完成", tiem: 1200 },
            {}
          );
          this.close();
        }
        return;
      } else {
        this.$zcx.zcx_msgbox(this, false, {}, tiemMsg);
        this.$zcx.zcx_msgbox(this, true, { str: "必填项错误", tiem: 1200 }, {});
      }
      this.$zcx.zcx_msgbox(this, false, {}, tiemMsg);
    },

    updateVisible(newVisible) {
      this.ZcxDropdowndata.visible = newVisible;
    },
    ZcxDropdownclose() {
      this.$refs.dropdown.close();
    },
    ZcxDropdownadd(event) {
      //传递event 调用Dropdown控件，传递Event到控件的打开事件，进一步处理
      // console.log(
      //   this.$zcx.zcx_getNestedValue(this, event.target.dataset.field, "123ce")
      // );
      // console.log(event.target.dataset.key);
      let data = {
        move: this.$zcx.div_move(event),
        Droptype: event.target.dataset.key,
        componentName: event.target.dataset.field,
      };
      this.$refs.dropdown.open(data);
      // console.log();
      // this.ZcxDropdowndata.visible2 = !this.ZcxDropdowndata.visible2;
    },
    handleClose(message) {
      // console.log("收到的消息:", message);
      this.$zcx.zcx_getNestedValue(this, message[0], message[1]);
      // 在这里你可以处理接收到的字符串
    },
    delcptd(e) {
      // console.log(e)
      if (e > -1 && e < this.form.cptd.length) {
        this.form.cptd.splice(e, 1); // 从索引 2 开始，删除 1 个元素
      }
    },
    addcptd() {
      this.form.cptd.push(["", null]);
      // console.log(this.form.cptd)
    },
    close() {
      // 子组件的关闭逻辑
      this.ins = 1;
      this.form = {
        cp: "", // 产品
        price: {
          // 价格
          zsprice: null, // 展示日常价
          novip: null, // 展示当此的零售价
          v1: null, // vip1客户价
          v2: null, // vip2客户价
          v3: null, // vip3客户价
        },
        payType: null, //支付单位 元，积分等...
        cpParameters: null, // 产品的单位 条（次，套，等）
        cptd: [
          // 产品特性 暂定5个参数，管理页面上可以添加 字段，
          // ["载重", null], //载重
          // ["轴位", null], //轴位
          // ["距离", null], //距离
          // ["路况", null], //路况
          // ["速度", null], //速度
          // ["", null],
        ],
        vipType: null, //客户端用，VIP等级 无等级就是看novip的价格
        offline: false, // ***
        ImageUrls: {}, //图片路径
        cptext: null, //产品介绍
        cjtext: null, //厂家介绍
        selectedFile: null,
        base64Data: "",
        previewUrl: null,
        zcx_imagevalue: [],
      };
    },
    zcx_created(e) {
      // 子组件的启动逻辑
      console.log("子组件的启动" + e);
    },
    chineseValues(e) {
      return this.$zcx.zcx_ChineseValue(e);
    },
  },
};
</script>

<style lang="less" scoped>
@import "./k.less";
</style>
